import axios from 'axios';
import URL from '@/views/pages/request';

class accountService {
  constructor() {}

  updateSoldBankAccounts(account, amount, state) {
    // console.log(account, amount, state);
    try {
      axios
        .post(URL.COMPTE_UPDATE, {
          id: account.id,
          numero_compte: account.numero_compte,
          libelle: account.libelle,
          solde:
            state === true
              ? parseInt(account.solde) + parseInt(amount)
              : parseInt(account.solde) - parseInt(amount),
        })
        .then((reponse) => {
          console.log(reponse.data);
        });
    } catch (error) {
      console.log(error);
    }
  }
}

export default new accountService();
